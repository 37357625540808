<template>
  <div
    class="library-assets-container"
    :class="containerClasses"
    @dragenter.prevent
    @dragleave.prevent="onDragleave"
    @drop.prevent="onDrop"
    @dragover.prevent="onDragover"
  >
    <div :class="{'image-preview-gallery': previewAssets.length}">
      <file-preview
        v-for="(asset, i) in previewAssets"
        :tabindex="i"
        :title="getFilePreviewTitle(asset)"
        :key="asset._id || asset.object_id"
        :src="asset.previewSrc"
        :filename="asset.filename"
        :has-error="asset.error"
        :is-uploading="asset.uploading"
        :upload-progress="asset.progress"
        @click.native="goToDetails(asset)"
        @click.ctrl.native="openDetailsInNewPage(asset)"
        @click.meta.native="openDetailsInNewPage(asset)"
        @click.middle.native="openDetailsInNewPage(asset)"
        @mouseenter.native="setHoveredAsset(asset)"
        @mouseleave.native="setHoveredAsset(null)"
      >
        <copy-button v-if="renderCopyButton" :copyText="asset.object_id" :ref="getCopyButtonRef(asset)"> </copy-button>
        <div class="staff-description" v-if="userIsStaffState && asset.description">
          {{ asset.description }}
        </div>
      </file-preview>

      <transition name="fade">
        <div class="drag-prompt" v-show="isWindowDragover">
          <div class="add-file-prompt">+</div>
          <div>Drop files here to upload</div>
        </div>
      </transition>

      <div v-if="!familyTreeLibraryAssetsState.length" class="empty-results">
        <div class="no-files-found-notice" v-if="filtersApplied">
          <p>No assets found for the selected filters.</p>
          <p><a @click="clearSearch">Clear search</a></p>
        </div>
        <template v-else>
          <div class="add-file-prompt">+</div>
          <div>Drag files here to start</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

import copyButton from '@/base/elements/buttons/copyButton.vue';

import FilePreview from '@/components/common/filePreview';

export default {
  props: {
    isWindowDragover: Boolean,
  },
  data() {
    return {
      isDragover: false,
      hoveredAsset: null,
      backTicKeyCode: 192, // ` key
    };
  },
  created() {
    if (this.renderCopyButton) {
      document.addEventListener('keydown', this.onKeyDown);
    }
  },
  destroyed() {
    if (this.renderCopyButton) {
      document.removeEventListener('keydown', this.onKeyDown);
    }
  },
  computed: {
    ...mapGetters([
      'familyTreeLibraryAssetsState',
      'familyTreeLibraryActiveFiltersState',
      'familyTreeLibraryAssetsMetaState',
      'userIsStaffState',
    ]),
    filtersApplied() {
      return !isEmpty(this.familyTreeLibraryActiveFiltersState);
    },
    containerClasses() {
      return {
        'is-dragover': this.isDragover,
        'is-empty': !this.familyTreeLibraryAssetsState.length,
      };
    },
    previewAssets() {
      return this.familyTreeLibraryAssetsState.map(asset => {
        return {
          ...asset,
          previewSrc: this.getPreviewSrc(asset),
        };
      });
    },
    renderCopyButton() {
      return this.userIsStaffState;
    },
  },
  methods: {
    onDragleave(e) {
      this.isDragover = false;
    },
    onDrop(e) {
      this.isDragover = false;
      let fileList = e.dataTransfer.files;
      if (fileList.length > 0) {
        this.$emit('drop-files', fileList);
      }
    },
    onDragover(e) {
      this.isDragover = true;
    },
    getPreviewSrc(asset) {
      if (asset.uploadingPreviewSrc) {
        return asset.uploadingPreviewSrc;
      }
      return asset.attachment;
    },
    clearSearch() {
      this.$emit('clear-filters');
    },
    goToDetails(asset) {
      if (asset.object_id) {
        const page = this.familyTreeLibraryAssetsMetaState.offset / this.familyTreeLibraryAssetsMetaState.limit + 1;
        this.$store.commit('setAssetsDetailsChunkPageNumberState', page);
        const idsList = this.familyTreeLibraryAssetsState.map(asset => asset.object_id).filter(id => Boolean(id));
        this.$store.commit('setAssetsDetailsIdsListState', idsList);
        this.$router.push({
          name: 'familytree-library-asset-details',
          params: {assetId: asset.object_id},
        });
      }
    },
    openDetailsInNewPage(asset) {
      if (asset.object_id) {
        let route = this.$router.resolve({
          name: 'familytree-library-asset-details',
          params: {assetId: asset.object_id},
        });
        window.open(route.href, '_blank');
      }
    },
    onKeyDown(e) {
      if (e.which === this.backTicKeyCode && this.hoveredAsset && this.hoveredAsset.object_id) {
        const ref = this.getCopyButtonRef(this.hoveredAsset);
        this.$refs[ref][0].clipBoardCopy();
      }
    },
    setHoveredAsset(asset) {
      this.hoveredAsset = asset;
    },
    getCopyButtonRef(asset) {
      return `copy-button-${asset._id || asset.object_id}`;
    },
    getFilePreviewTitle(asset) {
      if (this.userIsStaffState) {
        return asset.description;
      }
    },
  },
  components: {FilePreview, copyButton},
};
</script>

<style lang="scss" scoped>
.library-assets-container {
  border-radius: 4px;
  border: 1px dashed transparent;
  transition: all 0.2s ease;
  position: relative;
  min-height: 50vh;
  display: flex;

  &.is-empty {
    margin-top: 24px;
    border-color: rgba($border-color, 0.7);
    align-items: center;
    justify-content: center;
  }

  &.is-dragover {
    border-color: $link-color;
  }

  .library-asset-preview {
    margin: 20px;
  }

  .image-preview-gallery {
    height: 100%;
  }

  .empty-results,
  .drag-prompt {
    color: $mcr-light-grey;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: all 0.2s ease;

    > * {
      text-align: center;
    }
    .add-file-prompt {
      font-size: 88px;
      opacity: 0.5;
    }
  }

  .drag-prompt {
    border: 1px dashed $divider-line-light;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: rgba($background-alternate-color, 0.94);
    transition: all 0.2s ease;
    justify-content: flex-start;
    > *:first-child {
      margin-top: 14vh;
    }
  }

  .file-preview {
    box-shadow: $box-shadow-light;
    background-color: $background-alternate-color;
    align-self: flex-start;
    .staff-description {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 6px 12px;
      background-color: rgba(black, 0.75);
      color: white;
      z-index: 2;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .copy-button {
    z-index: 1;
  }
}
</style>
