<template>
  <transition name="expand">
    <slot v-if="isShown"></slot>
  </transition>
</template>

<script>
export default {
  props: {
    showMobile: {type: Boolean},
  },
  data() {
    return {
      isShown: this.showMobile || window.innerWidth > 1280,
    };
  },
  watch: {
    showMobile(value) {
      this.isShown = value;
    },
    ['$store.getters.windowWidthState'](value) {
      this.isShown = this.showMobile || value > 1280;
    },
  },
  name: 'AssetDetailsToggle',
};
</script>

<style lang="scss" scoped></style>
