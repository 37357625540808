export const UNKNOWN_NAME = 'Unknown';
export const CLAN_SURNAME_QUERY_PARAM = 'clan-surname';

export const CONTEXT_MENU_SECTIONS = {
  FULL_PROFILE: 'full_profile',
  EDIT: 'edit',
  FULL_PROFILE_EDIT: 'full_profile_edit',
  ADD_RELATIVE: 'add_relative',
  DELETE: 'delete',
  VIEW_TREE: 'view_tree',
  VIEW_LINEAGE: 'view_lineage',
};
export const DEMO_FAMILY_TREE_ID = 'bruce-lee';

export const PARENT_RELATION_TYPE = 'parent';
export const SIBLING_RELATION_TYPE = 'sibling';
export const SPOUSE_RELATION_TYPE = 'spouse';
export const CHILD_RELATION_TYPE = 'child';
export const RELATION_TYPE_NAMES = {
  [PARENT_RELATION_TYPE]: 'Parent',
  [SIBLING_RELATION_TYPE]: 'Sibling',
  [SPOUSE_RELATION_TYPE]: 'Spouse',
  [CHILD_RELATION_TYPE]: 'Child',
};
export const WIDTH = 140;
export const HEIGHT = 178;

export const QUICK_EDIT_HELPER_KEY = 'QUICKEDIT_DOUBLECLICK_TOOLTIP';
export const QUICK_VIEW_HELPER_KEY = 'QUICKVIEW_CLICK_TOOLTIP';
export const CARD_FULL_PROFILE_HELPER_KEY = 'CARD_FULL_PROFILE_TOOLTIP';
export const PROFILE_EDIT_HELPER_KEY = 'PROFILE_EDIT_TOOLTIP';
export const TREE_USAGE_TUTORIAL_KEY = 'TREE_USAGE_TUTORIAL_OVERLAY';
