<template>
  <a @click="download" class="download-action">
    <download-icon :size="21"></download-icon>
    Download
  </a>
</template>

<script>
import {saveAs} from 'file-saver';
import DownloadIcon from 'vue-material-design-icons/Download';

export default {
  props: {
    href: String,
    filename: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    download() {
      this.loading = true;
      let xhr = new XMLHttpRequest();
      xhr.open('GET', this.href, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        let blob = xhr.response;
        saveAs(blob, this.filename);
        this.loading = false;
      };
      xhr.onerror = () => {
        this.loading = false;
      };
      xhr.send();
    },
  },
  components: {DownloadIcon},
  name: 'AssetDownloadButton',
};
</script>

<style lang="scss" scoped>
.material-design-icon::v-deep {
  position: relative;
  top: 0px;
}
</style>
