<template>
  <div class="family-tree-library-landing-page">
    <div class="info_page">
      <header>
        <h1>Preserve Your Memories</h1>
        <p>
          Organize your family history treasures for future generations with our new
          <strong>Media & Files</strong> feature.
        </p>
        <mcr-buttons-row>
          <mcr-button-router-link
            :to="toRegister"
            :label="registerLabel"
            @click="trackPreRegisterAction"
          ></mcr-button-router-link>
        </mcr-buttons-row>
        <p class="login-link" v-if="!userIsLoggedInState">
          Existing user? <router-link :to="toLogin">Log in</router-link>
        </p>
        <img :src="$getAsset('/assets/services/library_splash2.png')" class="header-splash" />
      </header>
    </div>
    <mcr-footer></mcr-footer>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';

import mcrFooter from '@/components/common/mcrFooter';

export default {
  metaInfo: {
    title: 'Media & Files',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Preserve and organize your family memories, photos and files, and connect them with people in your family tree.',
      },
    ],
  },
  data() {
    return {
      registerLabel: 'Get Started',
    };
  },
  computed: {
    targetRouteString() {
      return this.$route.fullPath;
    },
    userIsLoggedInState() {
      return this.$store.getters.userIsLoggedInState;
    },
    toRegister() {
      return {name: 'register', query: {redirect: this.targetRouteString}};
    },
    toLogin() {
      return {name: 'login', query: {redirect: this.targetRouteString}};
    },
  },
  methods: {
    trackPreRegisterAction() {
      AnalyticsAmplitudeHandler.trackPreRegisterActionLinkClick(getRoutePageName(this.$route), this.registerLabel);
    },
  },
  components: {mcrButtonsRow, McrButtonRouterLink, mcrFooter},
};
</script>

<style lang="scss" scoped>
@import '@common/style/info.page';
</style>
