<template>
  <div class="family-tree-location-multiselect-container">
    <multiselect
      v-bind="$attrs"
      :ref="ref"
      :value="location"
      :options="options"
      :preserveSearch="false"
      :internalSearch="false"
      :clearOnSelect="true"
      :hide-selected="false"
      :show-no-options="false"
      :show-no-results="false"
      :loading="loading"
      :class="[multiselectClasses, classes]"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      label="display_text"
      trackBy="display_text"
      :option-height="optionHeight"
      :max-height="maxHeight"
      @open="open"
      @select="select"
      @search-change="onSearchChange"
      @close="close"
      class="location-multiselect"
    >
      <close-icon
        v-if="isMultiselectOpened || (alwaysShowRemove && location && location.display_text)"
        slot="clear"
        @mousedown.prevent.stop="clear()"
        class="caret-icon"
      ></close-icon>
      <div v-if="isMultiselectOpened || (alwaysShowRemove && location && location.display_text)" slot="caret"></div>
      <div slot="beforeList" class="helper-before-list" v-if="!loading && !searchQuery && isMultiselectOpened">
        Start typing to search for places...
      </div>
      <div slot="afterList" class="loading-after-list" v-if="loading">
        <span>Searching...</span>
      </div>
      <template v-slot:option="params">
        <signature-text-icon v-if="params.option.isAddCustom" :size="22"></signature-text-icon>
        <map-marker-icon v-else :size="22"></map-marker-icon>
        <span class="option-label text-sm">
          <span>{{ customLabel(params.option) }}</span>
        </span>
        <span class="level-name text-sm">{{ params.option.level_name }}</span>
      </template>
    </multiselect>
    <div class="input-warning" v-if="hasIdWarning">
      <alert-icon :size="16" /><span>Not a linked place.</span
      ><mcr-wiki-glossary-link naturalId="linked-places" text="Learn more" />
    </div>
  </div>
</template>

<script>
import McrWikiGlossaryLink from '@common/elements/glossary/mcrWikiGlossaryLink';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import AlertIcon from 'vue-material-design-icons/AlertOutline';
import CloseIcon from 'vue-material-design-icons/Close';
import MapMarkerIcon from 'vue-material-design-icons/MapMarkerOutline';
import SignatureTextIcon from 'vue-material-design-icons/SignatureText';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';

export default {
  inheritAttrs: false,
  props: {
    location: Object, // MultiPlace object {display_text, place_id, place_historical_id}
    familyTreeId: [String, Number],
    openDirection: {type: String, default: 'bottom'},
    multiselectClasses: {type: String, default: ''},
    alwaysShowRemove: {type: Boolean, default: false},
    showIdWarning: {type: Boolean, default: false},
  },
  data() {
    const optionHeight = 56;
    return {
      ref: 'multiselect',
      options: [],
      defaultOptions: [],
      loading: false,
      searchQuery: '',
      isMultiselectOpened: false,
      optionHeight: optionHeight,
    };
  },
  computed: {
    ...mapGetters([
      'placeAlternativesLoadingState',
      'familyTreeMostTaggedLocationsState',
      'familyTreeMostTaggedLocationsLoadingState',
      'windowWidthState',
    ]),
    hasIdWarning() {
      return this.showIdWarning && this.location && this.location.display_text && !this.location.place_id;
    },
    classes() {
      return this.hasIdWarning ? 'has-warning' : '';
    },
    maxHeight() {
      const visibleItemsCount = this.windowWidthState <= this.$breakpoints.tablet ? 3 : 5;
      return this.optionHeight * visibleItemsCount;
    },
  },
  methods: {
    open() {
      this.isMultiselectOpened = true;
      if (this.location && this.location.display_text) {
        this.options = [];
        this.$refs[this.ref].updateSearch(this.location.display_text);
      } else {
        this.getMostTaggedLocations();
      }
    },
    select(item) {
      const {display_text, place_historical_id, place_id, level} = item;
      this.$emit('select', {display_text, place_historical_id, place_id, level});
      this.defaultOptions = [];
    },
    onSearchChange(searchQuery) {
      this.options = [];
      this.searchQuery = searchQuery;
      if (this.location && searchQuery === this.location.display_text) {
        return;
      }
      if (!this.isMultiselectOpened) {
        return;
      }
      if (!searchQuery) {
        return this.getMostTaggedLocations();
      }
      this.loading = true;
      this.search(searchQuery);
    },
    search: debounce(function (searchQuery) {
      const searchOption =
        this.location && this.location.display_text === searchQuery
          ? this.location
          : {display_text: searchQuery, isAddCustom: true, place_historical_id: null, place_id: null};

      const placeAlternatives = this.$store.dispatch('searchPlaceAlternativesAction', {
        query: searchQuery,
        includeHistoricalPlaces: true,
      });
      const previouslyUsed = this.familyTreeId
        ? this.$store.dispatch('searchMostTaggedLocationsInFamilyTreeAction', {
            familyTreeId: this.familyTreeId,
            q: searchQuery,
          })
        : Promise.resolve();

      Promise.all([placeAlternatives, previouslyUsed])
        .then(() => {
          if (!this.searchQuery) {
            return;
          }
          let options = [
            ...this.$store.getters.familyTreeMostTaggedLocationsState,
            ...this.$store.getters.placeAlternativesState,
          ];
          options = uniqBy(options, 'display_text');
          const searchQueryInOptions = options.find(
            option => option.display_text.toLowerCase() === searchQuery.toLowerCase()
          );
          this.options = searchQueryInOptions ? options : [...options, searchOption];
        })
        .finally(() => {
          this.loading = false;
        });
    }, 500),
    clear() {
      this.$refs[this.ref].updateSearch('');
      this.$emit('clear');
      this.defaultOptions = [];
      this.loading = false;
    },
    close() {
      this.options = [];
      this.isMultiselectOpened = false;
    },
    getMostTaggedLocations() {
      if (!this.familyTreeId) {
        return;
      }
      if (this.defaultOptions.length) {
        this.options = this.defaultOptions;
        if (!this.searchQuery) {
          this.loading = false;
        }
        return;
      }
      this.$store
        .dispatch('searchMostTaggedLocationsInFamilyTreeAction', {familyTreeId: this.familyTreeId})
        .then(() => {
          this.defaultOptions = this.$store.getters.familyTreeMostTaggedLocationsState;
          this.options = this.$store.getters.familyTreeMostTaggedLocationsState;
          if (!this.searchQuery) {
            this.loading = false;
          }
        });
    },
    customLabel(option) {
      if (isEmpty(option)) return '';
      if (option.isAddCustom) return `+ Add "${option.display_text}"`;
      return option.display_text;
    },
  },
  components: {Multiselect, CloseIcon, AlertIcon, McrWikiGlossaryLink, MapMarkerIcon, SignatureTextIcon},
};
</script>

<style lang="scss" scoped>
.multiselect::v-deep {
  .multiselect__spinner {
    right: 30px;
  }
  .multiselect__input {
    padding-right: 24px;
  }

  .material-design-icon__svg {
    color: #666;
  }

  .multiselect__option {
    padding: 8px 12px;
    height: 56px;
    display: flex;
    align-items: center;

    .material-design-icon__svg {
      flex-grow: 0;
      flex-shrink: 0;
      color: $neutral-500;
    }

    .option-label {
      flex-grow: 1;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0 20px 0 8px;
      font-weight: 500;
    }

    .level-name {
      flex-shrink: 0;
      max-width: 60px;
      color: $neutral-500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.multiselect__option--highlight {
      .material-design-icon__svg,
      .level-name {
        color: white;
      }
    }
  }
}
</style>
