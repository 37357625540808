<template>
  <div
    ref="area-selection-overlay"
    class="area-select--overlay"
    :style="areaSelectOverlayStyle"
    @mousedown="startAreaSelection"
    @mousemove="moveAreaSelection"
    @mouseup="endAreaSelection"
    @touchstart.prevent="startAreaSelection"
    @touchmove.prevent="moveAreaSelection"
    @touchend.prevent="endAreaSelection"
  >
    <div class="area-select--selected" v-if="areaSelected" :style="areaSelectedStyle"></div>
    <slot></slot>
  </div>
</template>

<script>
import OcrAreaConfirmModal from '@common/elements/layouts/area-selection/OcrAreaConfirmModal';
import throttle from 'lodash/throttle';

export default {
  props: {
    imageWidth: Number,
    imageHeight: Number,
    scale: {type: Number, default: 1},
    renderScale: {type: Number, default: 1},
    url: String,
  },
  data() {
    return {
      areaSelected: null,
      areaSelectionStarted: false,
      areaSelectionEnded: false,
    };
  },
  computed: {
    areaSelectOverlayStyle() {
      return {
        position: 'absolute',
        width: this.imageWidth + 'px',
        height: this.imageHeight + 'px',
        display: 'block',
        cursor: 'crosshair',
      };
    },
    areaSelectedStyle() {
      return {
        border: '3px solid #920005',
        position: 'relative',
        'z-index': 100,
        width: Math.abs(this.areaSelected.x - this.areaSelected.x2) + 'px',
        height: Math.abs(this.areaSelected.y - this.areaSelected.y2) + 'px',
        left: Math.min(this.areaSelected.x, this.areaSelected.x2) - 4 + 'px',
        top: Math.min(this.areaSelected.y, this.areaSelected.y2) - 4 + 'px',
      };
    },
  },
  methods: {
    startAreaSelection(e) {
      this.$emit('area-selection-started');
      if (this.areaSelectionStarted) {
        return;
      }
      this.areaSelectionStarted = true;
      this.areaSelectionEnded = false;
      const rect = this.$refs['area-selection-overlay'].getBoundingClientRect();
      const mouseX = e.type === 'touchstart' ? e.changedTouches[0].pageX : e.clientX;
      const mouseY = e.type === 'touchstart' ? e.changedTouches[0].pageY : e.clientY;
      const x = (mouseX - rect.left) / this.scale;
      const y = (mouseY - rect.top) / this.scale;
      this.areaSelected = {
        x: x,
        y: y,
        x2: x + 10,
        y2: y + 10,
      };
    },
    moveAreaSelection: throttle(function (e) {
      if (!this.areaSelectionStarted) {
        return;
      }
      const rect = this.$refs['area-selection-overlay'].getBoundingClientRect();
      const mouseX = e.type === 'touchmove' ? e.touches[0].pageX : e.clientX;
      const mouseY = e.type === 'touchmove' ? e.touches[0].pageY : e.clientY;
      this.areaSelected.x2 = (mouseX - rect.left) / this.scale;
      this.areaSelected.y2 = (mouseY - rect.top) / this.scale;
    }, 10),
    endAreaSelection() {
      this.areaSelectionStarted = false;
      this.areaSelectionEnded = true;
      this.$emit('area-selected', this.areaSelected);
    },
    showModal() {
      this.$modal.show(
        OcrAreaConfirmModal,
        {
          imageWidth: this.imageWidth,
          imageHeight: this.imageHeight,
          url: this.url,
          x1: this.areaSelected.x,
          x2: this.areaSelected.x2,
          y1: this.areaSelected.y,
          y2: this.areaSelected.y2,
          renderScale: this.renderScale,
        },
        {height: 'auto', scrollable: true, classes: 'clear_modal white_modal', name: 'ocr'},
        {'before-close': event => {}}
      );
    },
  },
  name: 'AreaSelectionOverlay',
};
</script>

<style lang="scss" scoped>
.area-select--overlay{
  z-index: 100;
}
</style>
