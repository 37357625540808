<template>
  <div class="save-rotation-button">
    <div v-if="loading" class="loading">
      <div>Saving</div>
      <bounce-loading></bounce-loading>
    </div>

    <a v-else class="save-rotation" @click="saveRotation">Save Rotation</a>
  </div>
</template>

<script>
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';

export default {
  components: {BounceLoading},
  props: {
    assetId: String,
    angle: Number,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    saveRotation() {
      this.loading = true;
      this.$store
        .dispatch('assetRotateAction', {assetId: this.assetId, angle: -this.angle})
        .then(response => {
          this.$store.commit('mutateFamilyTreeAssetDetailsByIdState', {object_id: this.assetId, attachment: ''});

          this.$nextTick(() => {
            this.$emit('saved');
            const payload = {object_id: this.assetId, attachment: response.attachment};
            this.$store.commit('mutateFamilyTreeAssetDetailsByIdState', payload);
            this.$store.commit('mutateFamilyTreeLibraryAssetState', payload);
            this.loading = false;
          });
        })
        .catch(() => {
          this.$toasted.error('Saving failed');
          this.loading = false;
        });
    },
  },
  name: 'SaveRotationButton',
};
</script>

<style lang="scss" scoped>
.save-rotation-button {
  margin-right: 12px;
  margin-bottom: 10px;

  .loading,
  a {
    color: $mcr-pale-red;
  }

  .loading {
    display: flex;

    .bounce-loading {
      margin-left: 5px;
    }

    .bounce-loading::v-deep > div {
      background-color: $mcr-pale-red;
    }
  }
}
</style>
