<template>
  <div
    class="images-gallery-overlay"
    v-bind:class="{'info-bar-shown': infoBarShown, 'info-bar-left-side': isInfoBarLeftSide}"
    @touchstart="touchstart"
    @touchend="touchend"
  >
    <close-button v-if="showClose" @click="clickClose" class="dark"></close-button>
    <div class="content-container">
      <div v-if="!infoBarShown && $slots['info-bar']" class="hide-button show-button" @click="toggleInfoBar">
        &lsaquo; Details
      </div>
      <transition :name="infoBarTransitionName">
        <div v-if="infoBarShown" class="info-bar">
          <div v-if="infoBarShown" class="hide-button" @click="toggleInfoBar">Hide &rsaquo;</div>
          <slot name="info-bar"></slot>
        </div>
      </transition>
      <div class="image-port" :class="imagePortClass">
        <div class="image-container" :class="imageContainerClasses">
          <transition :name="imageSlideTransitionName">
            <div v-show="showImage" class="image">
              <video v-if="isVideo" class="video-player" controls>
                <source :src="src"/>
                Your browser does not support the video tag.
              </video>
              <audio v-else-if="isAudio" class="audio-player" controls>
                <source :src="src"/>
                Your browser does not support the audio tag.
              </audio>
              <template v-else>
                <img @mousedown.prevent ref="image" :src="src" :key="transitionKey || src" :style="imageStyle" @load="onImageLoad"/>
                                <area-selection-overlay v-if="isModeAreaSelection && isImageLoaded"
                                        ref="area-selection-overlay"
                                        :image-width="imageWidth"
                                        :image-height="imageHeight"
                                        :scale="1"
                                        :render-scale="1"
                                        :url="src"
                                        @area-selected="showConfirmationOcr"
                                        @area-selection-started="showConfirmationOcr(false)"
                >
                </area-selection-overlay>
              </template>
            </div>
          </transition>
          <slot name="image-title" v-if="showImage"></slot>
          <mcr-loading-indicator v-show="!src" :loading="true"/>
          <div class="filename">{{ filename }}</div>
        </div>
        <div v-if="!isModeAreaSelection" class="gallery-nav prev" :class="{active: hasPrevious}" @click="clickPrevious"></div>
        <div v-if="!isModeAreaSelection" class="gallery-nav next" :class="{active: hasNext}" @click="clickNext"></div>
      </div>
    </div>
    <slot name="modals"></slot>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import AreaSelectionOverlay from '@common/elements/layouts/area-selection/AreaSelectionOverlay';

import {isVideoFile, isAudioFile} from '@/components/common/filePreviewHelper';
import McrButton from "../buttons/mcrButton.vue";

export default {
  props: {
    src: String,
    transitionKey: {type: String, required: false}, //if src is not unique, pass this value
    hasNext: Boolean,
    hasPrevious: Boolean,
    showClose: {type: Boolean, default: true},
    imageContainerClasses: {type: [String, Object, Array], required: false},
    allowTouch: {type: Boolean, required: false, default: true},
    isInfoBarLeftSide: {type: Boolean, default: false},
    initInfoBarHidden: {type: Boolean, default: false},
    rotateImage: {type: Number, default: 0},
    filename: String,
    isModeAreaSelection: {type: Boolean, default: false},
  },
  data() {
    return {
      showImage: true,
      isImageLoaded: false,
      infoBarShown: !this.initInfoBarHidden,
      imageSlideTransitionName: '',
      infoBarTransitionName: this.isInfoBarLeftSide ? 'info-bar-slide-left' : 'info-bar-slide-right',
      touchStartX: null,
    };
  },
  created() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },
  computed: {
    imagePortClass() {
      let classes = [];
      if (!this.infoBarShown) {
        classes.push('is-full-screen');
      }
      return classes;
    },
    imageStyle() {
      return {transform: `rotate(${this.rotateImage}deg)`};
    },
    isVideo() {
      return isVideoFile(this.filename);
    },
    isAudio() {
      return isAudioFile(this.filename);
    },
    imageWidth() {
      return this.$refs.image ? this.$refs.image.width : 0;
    },
    imageHeight() {
      return this.$refs.image ? this.$refs.image.height : 0;
    },
  },
  methods: {
    touchstart(event) {
      if (event.touches.length === 1) {
        this.touchStartX = event.changedTouches[0].pageX;
      }
    },
    touchend(event) {
      if (!this.allowTouch || this.isModeAreaSelection) {
        return;
      }
      if (this.touchStartX && this.touchStartX - event.changedTouches[0].pageX < 0) {
        this.clickPrevious();
      }
      if (this.touchStartX && this.touchStartX - event.changedTouches[0].pageX > 0) {
        this.clickNext();
      }
    },
    clickClose() {
      this.$emit('click-close');
    },
    clickNext() {
      if (this.hasNext) {
        this.imageSlideTransitionName = 'image-slide-right';
        this.showImage = false;
        this.isImageLoaded = false;
        this.$emit('hide-image');
        setTimeout(() => {
          this.showImage = true;
          this.$emit('click-next');
          this.imageSlideTransitionName = 'image-slide-left';
        }, 300);
      }
    },
    clickPrevious() {
      if (this.hasPrevious) {
        this.imageSlideTransitionName = 'image-slide-left';
        this.showImage = false;
        this.isImageLoaded = false;

        this.$emit('hide-image');
        setTimeout(() => {
          this.showImage = true;
          this.$emit('click-previous');
          this.imageSlideTransitionName = 'image-slide-right';
        }, 300);
      }
    },
    keyupHandler(event) {
      if (['INPUT', 'TEXTAREA'].includes(event.target.tagName)) {
        return;
      }
      if (event.keyCode == 39) {
        return this.clickNext();
      }
      if (event.keyCode == 37) {
        return this.clickPrevious();
      }
      if (event.keyCode == 27) {
        this.$emit('click-close');
      }
    },
    toggleInfoBar() {
      this.infoBarShown = !this.infoBarShown;
    },
    onImageLoad() {
      this.isImageLoaded = true;
      this.$emit('image-loaded');
    },
    showConfirmationOcr(value) {

      this.$emit('area-selected', value);
    },
    confirmOcrSelection() {
      if (this.$refs['area-selection-overlay'].areaSelectionEnded) {
        return this.$refs['area-selection-overlay'].showModal();
      }
      this.$toasted.error('Select image area to OCR.');
    },
  },
  components: {McrButton, CloseButton, AreaSelectionOverlay},
};
</script>

<style lang="scss" scoped>
$info-bar-width: 400px;
$info-bar-z-index: 1;
$hide-button-z-index: 2; // calc(#{$info-bar-z-index} + 1); - calc z-index doesn't work in IE
$close-button-z-index: 2; //calc(#{$info-bar-z-index} + 1); - calc z-index doesn't work in IE

.images-gallery-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.96);
  top: 0;
  left: 0;
  z-index: 12347; // calc(#{$modal-z-index} + 1); - calc z-index doesn't work in IE
  color: $mcr-light-grey;

  .hide-button {
    padding: 35px 0 0 35px;
    cursor: pointer;
    position: absolute;
    top: 1px;
    left: 0px;
    color: $supplemental-text-color;
    z-index: $hide-button-z-index;
    opacity: 0.5;
    transition: all 0.3s ease;

    &.show-button {
      right: 65px;
      left: auto;
      white-space: nowrap;
    }

    &:hover {
      opacity: 1;
    }
  }

  .content-container {
    height: 100%;

    .info-bar {
      padding-top: 25px;
      position: absolute;
      z-index: $info-bar-z-index;
      right: 0;
      top: 0;
      width: $info-bar-width;
      height: 100%;
      box-sizing: border-box;
      background-color: #1c1c1c;
      overflow: auto;
    }

    .image-port {
      position: absolute;
      top: 0;
      right: $info-bar-width;
      width: calc(100% - #{$info-bar-width});
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.4s ease;

      .mcr-loading-indicator {
        opacity: 0.2;
        margin: auto;
        position: absolute;
      }

      .video-player {
        max-width: 100%;
        max-height: 100%;
        z-index: 10;
      }

      .audio-player {
        width: 100%;
        z-index: 10;
      }

      .image-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .image {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          max-width: calc(100% - 120px);
          max-height: calc(100% - 160px);
        }

        &:not(.is-image) {
          box-sizing: border-box;
          padding: 16%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            margin-bottom: 32px;
          }

          .image-title {
            font-size: 21px;
            text-align: center;
          }

          @media only screen and (max-width: $breakpoint-mobile) {
            padding: 12%;
            .image-title {
              text-align: left;
            }
          }
        }

        .filename {
          position: absolute;
          bottom: 30px;
        }
      }

      .gallery-nav {
        position: absolute;
        color: #fff;
        font-size: 64px;
        cursor: pointer;
        opacity: 0.15;
        transition: all 0.5s ease;
        height: 80%;
        width: 20%;
        top: 10%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &.prev {
          left: 0px;
          padding-left: 20px;

          &:after {
            content: '\2039';
          }
        }

        &.next {
          right: 0px;
          text-align: right;
          justify-content: flex-end;
          padding-right: 20px;

          &:after {
            content: '\203A';
          }
        }

        &:hover {
          opacity: 0.4;
        }

        &:not(.active) {
          cursor: default;
          opacity: 0;
        }
      }
    }
  }

  .close-btn {
    position: absolute;
    z-index: $close-button-z-index;
    right: 0px;
    top: 0px;
    padding: 15px 15px 0 0;
  }
}

.images-gallery-overlay.info-bar-left-side {
  .info-bar,
  .hide-button {
    left: 0;
  }

  .image-port {
    left: $info-bar-width;
  }
}

.images-gallery-overlay:not(.info-bar-shown) .image-port {
  width: 100%;
  right: 0;
  left: 0;
}

@media only screen and (max-width: $breakpoint-desktop) {
  $gallery-preview-mobile-top-bar-height-expanded: 130px;
  .images-gallery-overlay {
    .hide-button {
      display: none;
    }

    .content-container {
      .image-port {
        width: 100%;
        height: calc(100% - #{$gallery-preview-mobile-top-bar-height-expanded});
        margin-top: $gallery-preview-mobile-top-bar-height-expanded;
        left: 0;

        &.is-full-screen {
          height: 100%;
          margin-top: 0;
        }

        .image-container {
          img {
            max-width: calc(100% - 60px);
            max-height: calc(100% - 80px);
          }
        }

        @media only screen and (max-width: $breakpoint-mobile) {
          .image-container {
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }

      .info-bar {
        padding-top: 0;
        background-color: rgba(black, 0.9);
        top: 0;
        width: 100%;
        height: auto;
        max-height: 100%;
        overflow: auto;
      }
    }
  }
}

.image-slide-right-enter-active,
.image-slide-left-enter-active,
.image-slide-right-leave-active,
.image-slide-left-leave-active {
  transition: all 0.2s ease;
}

.image-slide-right-enter,
.image-slide-right-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}

.image-slide-left-enter,
.image-slide-left-leave-to {
  transform: translateX(200px);
  opacity: 0;
}
</style>
