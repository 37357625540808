<template>
  <div class="file-preview" @focusout="endScroll" @focus="startScroll" @touchstart="focus">
    <div class="aspect-ratio"></div>
    <div class="file-preview-content">
      <template v-if="isVideoFile">
        <video :src="videoSrc" class="video-first-frame"></video>
      </template>
      <div v-else-if="isAudioFile" class="icon-preview">
        <music :size="175"></music>
      </div>
      <img v-else :data-src="imageSrc" :class="{'is-image': isImageFile}" class="lazyload" />
      <div
        v-if="!hasError && !isUploading && label"
        class="label-text"
        @mouseenter="startScroll"
        @mouseleave="endScroll"
      >
        <div class="label-play" v-if="isVideoFile || isAudioFile">
          <play-circle :size="50"></play-circle>
          <span class="label" ref="labelContainer" :title="label"
            ><span ref="labelText">{{ label }}</span></span
          >
        </div>
        <div v-else>{{ label }}</div>
      </div>
      <file-uploading-mask
        :has-error="hasError"
        :is-uploading="isUploading"
        :upload-progress="uploadProgress"
      ></file-uploading-mask>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {NetworkAPIThumbnailURL} from '@common/network/network.utils';
import Music from 'vue-material-design-icons/Music';
import PlayCircle from 'vue-material-design-icons/PlayCircle';

import consts from '@/base/utils/consts';

import FileUploadingMask from '@/components/common/fileUploadingMask';

import {getPreviewSrcByFileExtension} from './filePreviewHelper';

export default {
  components: {FileUploadingMask, PlayCircle, Music},
  props: {
    src: String,
    hasError: Boolean,
    isUploading: Boolean,
    uploadProgress: String,
    filename: {type: String, required: false},
  },
  methods: {
    startScroll(e) {
      this.$el.focus();
      if (!this.$refs.labelText) return;
      const containerWidth = this.$refs.labelContainer.offsetWidth;
      const textWidth = this.$refs.labelText.offsetWidth;
      if (textWidth > containerWidth) {
        let dif = textWidth - containerWidth + 5;
        let timeForTransition = dif * 0.02;
        this.$refs.labelText.style.transition = `all ${timeForTransition}s linear`;
        this.$refs.labelText.style.left = `-${dif}px`;
      }
    },
    endScroll(e) {
      if (!this.$refs.labelText) return;

      this.$refs.labelText.style.left = `0px`;
      this.$refs.labelText.style.transition = 'none';
    },
    focus(e) {
      this.$el.focus();
    },
  },
  computed: {
    extension() {
      return this.filename && this.filename.includes('.') ? this.filename.split('.').pop().toLowerCase() : '';
    },
    isImageFile() {
      return consts.imageExtensions.includes(this.extension);
    },
    isVideoFile() {
      return consts.videoExtensions.includes(this.extension);
    },
    isAudioFile() {
      return consts.audioExtensions.includes(this.extension);
    },
    label() {
      return this.isImageFile ? '' : this.filename;
    },
    imageSrc() {
      if (this.isImageFile) {
        return NetworkAPIThumbnailURL + '?url=' + encodeURIComponent(this.src) + '&fit=300';
      }
      return getPreviewSrcByFileExtension(this, this.extension);
    },
    videoSrc() {
      return this.src + '#t=0.001';
    },
  },
};
</script>

<style lang="scss" scoped>
.file-preview {
  position: relative;
  border: 1px solid $photo-border-color;
  background-color: $photo-border-color;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.1s ease;

  &:hover {
    .label-play {
      svg {
        fill: $link-color;
      }
    }
  }

  video {
    user-select: none;
    pointer-events: none;
  }

  .aspect-ratio {
    padding-top: 100%;
    border-radius: 4px;
  }

  .video-first-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .icon-preview {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    color: $tan-200;
  }

  .file-preview-content {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    img:not(.is-image) {
      width: 60%;
      height: 60%;
      object-fit: contain;
      position: absolute;
      top: 20%;
      left: 20%;
      opacity: 0.2;
    }

    .label-text {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      padding: 24px;
      @media only screen and (max-width: $breakpoint-mobile-se) {
        padding: 12px;
      }

      div {
        max-height: 100%;
        word-break: break-all;
      }
    }

    .label-play {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: $neutral-900;

      .label {
        position: absolute;
        color: $white;
        margin-top: 80px;
        background-color: rgba($title-color, 0.6);
        border-radius: 4px;
        padding: 4px;
        white-space: nowrap;
        overflow: hidden;
        width: 80%;

        span {
          position: relative;
          left: 0;
        }
      }
    }
  }
  &:hover {
    border: 1px solid $link-color;
  }
}
</style>
