<template>
  <div class="ocr-area-confirm-modal">
    <h5>Selected Area OCR</h5>
    <div class="select-language">
      <div class="field radio" v-for="(name, value) in langOptions">
        <input :id="value" type="radio" name="language-radio" :value="value" v-model="lang" @change="onLangChange" />
        <label :for="value">{{ name }}</label>
      </div>
    </div>
    <div class="ocr-text">{{ ocrText }}</div>
    <div class="error">{{ errorText }}</div>
    <div class="loading supplemental" v-if="ocrLoading">Processing image snippet...</div>

    <div class="image-container">
      <div :style="imagePreviewStyle"></div>
    </div>

    <div class="buttons">
      <mcr-button class="parser-button" v-if="cacheKey" @click="openParser">
        <key-cup>T</key-cup>
        Open in parser
      </mcr-button>
      <mcr-button @click="runOcrSelection" :loading="ocrLoading">{{ okLabel }}</mcr-button>
      <mcr-button class="cancel" @click="$emit('close')">Close</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import KeyCup from '@common/elements/icons/KeyCup';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getOcrParserAdminUrl} from '@common/utils/utils.admin';
import {mapGetters} from 'vuex';

export default {
  props: {
    url: String,
    imageWidth: Number,
    imageHeight: Number,
    x1: Number,
    y1: Number,
    x2: Number,
    y2: Number,
    renderScale: Number,
  },
  created() {
    document.addEventListener('keydown', this.onShortcutPress);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onShortcutPress);
  },
  data() {
    const savedLang =
      this.$store.getters.ocrLangBySourceIdState[this.$route.params.sourceId || this.$route.params.zupuId];
    const source = this.$store.getters.sourceDetailsState;
    return {
      ocrText: '',
      errorText: '',
      cacheKey: '',
      ocrLoading: false,
      lang: savedLang || (source && source.zupu_id ? 'cn' : 'en'),
    };
  },
  mounted() {
    this.runOcrSelection();
  },
  computed: {
    ...mapGetters(['userIsStaffState']),
    langOptions() {
      let _langOptions = {
        en: 'English',
        cn: 'Chinese Autodetect',
        cn_vert: 'Chinese Vertical',
        cn_hor: 'Chinese Horizontal',
      };
      if (this.userIsStaffState) {
        _langOptions.external_ocr = 'External OCR';
      }
      return _langOptions;
    },
    displayScale() {
      if (this.width < 150) {
        return 5;
      }
      return 1;
    },
    okLabel() {
      return this.ocrText ? 'Re-run OCR' : 'Run OCR';
    },
    left() {
      return Math.min(this.x1, this.x2);
    },
    top() {
      return Math.min(this.y1, this.y2);
    },
    width() {
      return Math.abs(this.x1 - this.x2);
    },
    height() {
      return Math.abs(this.y1 - this.y2);
    },
    imagePreviewStyle() {
      return {
        background: `#fff url('${this.url}') -${this.left * this.displayScale}px -${this.top * this.displayScale}px / ${
          this.imageWidth * this.displayScale
        }px ${this.imageHeight * this.displayScale}px no-repeat`,
        width: this.width * this.displayScale + 'px',
        height: this.height * this.displayScale + 'px',
        margin: '20px 0',
      };
    },
  },
  methods: {
    openParser() {
      window
        .open(
          getOcrParserAdminUrl(
            this.$route.params.sourceId || '',
            this.$route.params.zupuId || '',
            this.$route.query.page || 1,
            this.$route.query.page_id || '',
            this.cacheKey
          ),
          '_blank'
        )
        .focus();
    },
    runOcrSelection() {
      this.ocrLoading = true;
      this.ocrText = '';
      this.errorText = '';
      const params = {
        url: this.url,
        lang: this.lang,
        x1: Math.floor(Math.min(this.x1, this.x2) * this.renderScale) - 4,
        x2: Math.ceil(Math.max(this.x1, this.x2) * this.renderScale) + 4,
        y1: Math.floor(Math.min(this.y1, this.y2) * this.renderScale) - 4,
        y2: Math.ceil(Math.max(this.y1, this.y2) * this.renderScale) + 4,
      };
      this.$store
        .dispatch('runOcrForImageArea', params)
        .then(params => {
          this.ocrLoading = false;
          const fullText = params.full_text ? params.full_text.trim() : '';
          this.ocrText = fullText || 'Text not detected.';
          this.cacheKey = params.cache_key;
          AnalyticsAmplitudeHandler.trackSelectAreaOcrResultsEvent(this.lang, Boolean(fullText));
        })
        .catch(err => {
          this.ocrLoading = false;
          const errorText =
            err.response && err.response.data && err.response.data.detail ? err.response.data.detail[0].msg : err;
          this.errorText = 'Error during OCR: ' + errorText;
        });
    },
    onLangChange(event) {
      this.$store.commit('mutateOcrLangBySourceIdState', {
        id: this.$route.params.sourceId || this.$route.params.zupuId,
        lang: event.target.value,
      });
    },
    onShortcutPress(event) {
      if (event.key === 't' && this.cacheKey) {
        this.openParser();
      }
    },
  },
  components: {McrButton, KeyCup},
  name: 'OcrAreaConfirmModal',
};
</script>

<style lang="scss" scoped>
.ocr-area-confirm-modal {
  padding: 25px;

  .select-language {
    margin: 10px 0 15px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
    border-bottom: 1px solid $divider-line-light;

    .field {
      margin-right: 10px;
    }
  }

  .image-container {
    overflow: auto;
    max-width: calc(100vw - 50px);
  }

  .ocr-text {
    white-space: pre-wrap;
  }
}
</style>
