<template>
  <div class="family-tree-library-asset-details-page">
    <asset-details-container></asset-details-container>
  </div>
</template>

<script>
import {initGooglePlacesScript} from '@common/utils/script.loader';

import AssetDetailsContainer from './details/AssetDetailsContainer';

export default {
  created() {
    initGooglePlacesScript();
  },
  components: {AssetDetailsContainer},
};
</script>

<style lang="scss" scoped></style>
