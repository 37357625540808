<template>
  <div class="asset-details-container">
    <images-gallery-overlay
      ref="images-gallery-overlay"
      :src="src"
      :transitionKey="assetId"
      :has-next="hasNext"
      :has-previous="hasPrevious"
      :show-close="true"
      :imageContainerClasses="imageContainerClasses"
      :allow-touch="!areMobileDetailsShown"
      :rotate-image="rotationAngle"
      :filename="assetDetails && assetDetails.filename"
      :is-mode-area-selection="isOcrSelectionMode"
      @area-selected="showConfirmationOcr"
      @click-close="goToLibrary"
      @click-next="navigate(true)"
      @click-previous="navigate(false)"
      @hide-image="onHideImage"
      @image-loaded="onImageLoad"
    >
      <div class="info-bar-content" slot="info-bar">
        <h5 v-if="openedFromLibrary">{{ header }}</h5>
        <div class="file-count" v-if="openedFromLibrary">{{ filesCountLabel }}</div>
        <div class="info-bar-actions" v-if="areDetailsShown">
          <a v-if="canRemovePicture" class="remove-picture" @click="showDeleteAssetModal"
            ><delete-icon></delete-icon> <span>Delete</span></a
          >
          <asset-download-button
            :href="assetDetails.attachment"
            :filename="assetDetails.filename"
          ></asset-download-button>
          <a class="rotate-picture" @click="rotateImage" v-if="isFileImage"
            ><rotate-icon></rotate-icon> <span>Rotate</span></a
          >
          <save-rotation-button
            v-if="rotationAngle && isEditAllowed"
            :angle="rotationAngle"
            :asset-id="assetId"
            @saved="onRotationSaved"
          ></save-rotation-button>
          <a class="toggle-details" @click="toggleDetails"
            ><pencil-icon></pencil-icon> <span>{{ toggleDetailsText }}</span></a
          >
          <template v-if="isFileImage">

          <a class="ocr-picture" @click="toggleOcrSelection"
            ><ocr-icon></ocr-icon>
            <span v-if="isOcrSelectionMode">Cancel</span>
            <span v-else>Extract Text</span>
          </a>
          <a @click="confirmOcrSelection" class="ocr-select-confirm" :class="confirmActionClasses" v-if="isOcrSelectionMode && isOcrSelectedOnce">Extract selected area</a>
          <span class="ocr-select-waiting" v-if="isOcrSelectionMode && !isOcrSelectedOnce">select the area to extract the text from</span>
          </template>

        </div>
        <asset-details-toggle v-if="areDetailsShown" :show-mobile="areMobileDetailsShown">
          <div v-if="isEditAllowed" class="edit-form">
            <asset-edit-form-container :asset="assetDetails"></asset-edit-form-container>
            <asset-upload-meta :asset="assetDetails"></asset-upload-meta>
          </div>

          <div v-else class="readonly">
            <hr />
            <p v-html="getTaggedPersonsReadonlyHtml"></p>
            <p v-html="getPlaceDateReadonlyHtml"></p>
            <p class="file-description">{{ assetDetails.description }}</p>
            <asset-upload-meta :asset="assetDetails"></asset-upload-meta>
          </div>
        </asset-details-toggle>
      </div>
      <div slot="image-title" v-if="showFilename" class="image-title">{{ assetDetails.filename }}</div>
    </images-gallery-overlay>
    <modal :name="deleteModalName" classes="clear_modal">
      <delete-confirm-modal-content
        :modal-name="deleteModalName"
        :loading="familyTreeAssetDeleteLoadingState"
        @confirm="deleteAsset"
      >
        <h5>Are you sure you want to delete this file?</h5>
      </delete-confirm-modal-content>
    </modal>
  </div>
</template>

<script>
import ImagesGalleryOverlay from '@common/elements/gallery/imagesGalleryOverlay';
import debounce from 'lodash/debounce';
import DeleteIcon from 'vue-material-design-icons/Delete';
import PencilIcon from 'vue-material-design-icons/Pencil';
import RotateIcon from 'vue-material-design-icons/Reload';
import OcrIcon from 'vue-material-design-icons/Ocr';
import {mapGetters} from 'vuex';

import DeleteConfirmModalContent from '@/base/elements/modals/deleteConfirmModalContent';

import {getNonImagePreviewSrcByFilename, isImageFile} from '@/components/common/filePreviewHelper';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

import AssetDetailsToggle from '../common/AssetDetailsToggle';
import AssetDownloadButton from '../common/AssetDownloadButton';
import AssetEditFormContainer from '../common/AssetEditFormContainer';
import AssetUploadMeta from '../common/AssetUploadMeta';
import SaveRotationButton from '../common/SaveRotationButton';

export default {
  beforeMount() {
    this.saveChunkPageNumber(this.$store.getters.assetsDetailsChunkPageNumberState);
  },
  mounted() {
    this.fetchAssetDetails();
  },
  watch: {
    assetId() {
      this.areDetailsHidden = false;
      this.fetchAssetDetails();
    },
  },
  data() {
    return {
      maxChunkPageNumber: null,
      minChunkPageNumber: null,
      deleteModalName: 'delete-modal',
      areMobileDetailsShown: false,
      areDetailsHidden: false,
      navigationNextThrottled: false,
      navigationBackThrottled: false,
      rotationAngle: 0,
      isOcrSelectionMode: false,
      isOcrAreaSelected: false,
      isOcrSelectedOnce: false,

      confirmActionClasses: [],
    };
  },
  computed: {
    ...mapGetters([
      'assetsDetailsIdsListState',
      'familyTreeAssetDetailsByIdState',
      'familyTreeLibraryAssetsMetaState',
      'familyTreeLibraryActiveFiltersState',
      'assetsDetailsChunkPageNumberState',
      'familyTreeAssetDeleteLoadingState',
      'activeFamilyTreeIdState',
    ]),
    assetId() {
      return this.$route.params.assetId;
    },
    assetDetails() {
      return this.familyTreeAssetDetailsByIdState[this.assetId];
    },
    openedFromLibrary() {
      return Boolean(this.assetsDetailsIdsListState.length);
    },
    src() {
      if (this.assetDetails && this.assetDetails.attachment) {
        return getNonImagePreviewSrcByFilename(this, this.assetDetails.filename) || this.assetDetails.attachment;
      }
    },
    filesCountLabel() {
      const totalCount = this.familyTreeLibraryAssetsMetaState.total_count;
      return this.openedFromLibrary ? `${this.currentCount} of ${totalCount} files` : '';
    },
    header() {
      return this.familyTreeLibraryActiveFiltersState.length ? 'Search Results' : 'Media & Files';
    },
    isFileImage() {
      return this.assetDetails ? isImageFile(this.assetDetails.filename) : false;
    },
    showFilename() {
      return this.assetDetails && !this.isFileImage;
    },
    hasNext() {
      return this.openedFromLibrary && this.currentCount < this.familyTreeLibraryAssetsMetaState.total_count;
    },
    hasPrevious() {
      return this.openedFromLibrary && this.currentCount > 1;
    },
    currentIndex() {
      return (
        this.assetsDetailsIdsListState.indexOf(this.assetId) +
        (this.minChunkPageNumber - 1) * this.familyTreeLibraryAssetsMetaState.limit
      );
    },
    currentCount() {
      return this.currentIndex + 1;
    },
    imageContainerClasses() {
      return {'is-image': this.isFileImage};
    },
    toggleDetailsText() {
      return this.areMobileDetailsShown ? 'Hide Details' : 'Details';
    },
    areDetailsShown() {
      return !this.areDetailsHidden && this.assetDetails;
    },
    familyTreeId() {
      return this.assetDetails ? this.assetDetails.family_tree_id : null;
    },
    isEditAllowed() {
      return this.assetDetails && (this.assetDetails.is_owner || this.assetDetails.is_editor);
    },
    canRemovePicture() {
      return this.isEditAllowed;
    },
    getTaggedPersonsReadonlyHtml() {
      const persons = this.assetDetails && this.assetDetails.persons ? this.assetDetails.persons : [];
      let str = '';
      for (let i = 0; i < persons.length; i++) {
        const separator = i === persons.length - 1 ? ' and ' : ', ';
        const personHtml = `<strong>${persons[i].full_name || UNKNOWN_NAME}</strong>`;
        str = str ? `${str}${separator}${personHtml}` : personHtml;
      }
      return str;
    },
    getPlaceDateReadonlyHtml(assetId) {
      const asset = this.assetDetails;
      const location = asset.location || (asset.location_tag ? asset.location_tag.display_text : '');
      const dateLocation = [asset.event_date, location];
      return dateLocation
        .filter(str => str)
        .map(str => `<strong>${str}</strong>`)
        .join(' in ');
    },
  },
  methods: {
    fetchAssetDetails: debounce(function () {
      this.$store.dispatch('getAssetDetailsAction', {assetId: this.assetId});
    }, 500),
    goToLibrary() {
      this.$router.push({name: 'familytree-library', params: {id: this.familyTreeId || this.activeFamilyTreeIdState}});
    },
    async getNextAssetId() {
      if (
        this.assetsDetailsIdsListState.indexOf(this.assetId) === this.assetsDetailsIdsListState.length - 1 &&
        this.hasNext
      ) {
        this.navigationNextThrottled = true;
        await this.fetchAssetsList(this.maxChunkPageNumber + 1, true);
        this.navigationNextThrottled = false;
      }
      const currentIndex = this.assetsDetailsIdsListState.indexOf(this.assetId);
      const nextAssetId = this.assetsDetailsIdsListState[currentIndex + 1];
      return nextAssetId;
    },
    async getPreviousAssetId() {
      if (this.assetsDetailsIdsListState.indexOf(this.assetId) === 0 && this.hasPrevious) {
        this.navigationBackThrottled = true;
        await this.fetchAssetsList(this.minChunkPageNumber - 1, false);
        this.navigationBackThrottled = false;
      }
      return this.assetsDetailsIdsListState[this.assetsDetailsIdsListState.indexOf(this.assetId) - 1];
    },
    navigate(toNext) {
      if (toNext) {
        return this.navigationNextThrottled ? null : this.clickNext();
      }
      return this.navigationBackThrottled ? null : this.clickPrevious();
    },
    async clickNext() {
      const assetId = await this.getNextAssetId();
      this.$router.push({name: 'familytree-library-asset-details', params: {assetId}});
    },
    async clickPrevious() {
      const assetId = await this.getPreviousAssetId();
      this.$router.push({name: 'familytree-library-asset-details', params: {assetId}});
    },
    fetchAssetsList(page, isNextPge) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getFamilyTreeLibraryFilteredAssetsChunkAction', {page}).then(response => {
          const newIds = response.objects
            .map(asset => asset.object_id)
            .filter(id => !this.assetsDetailsIdsListState.includes(id));
          const newAssets = isNextPge
            ? [...this.assetsDetailsIdsListState, ...newIds]
            : [...newIds, ...this.assetsDetailsIdsListState];
          this.saveChunkPageNumber(page);
          this.$store.commit('setAssetsDetailsIdsListState', newAssets);
          resolve();
        });
      });
    },
    saveChunkPageNumber(page) {
      this.maxChunkPageNumber =
        this.maxChunkPageNumber && this.maxChunkPageNumber > page ? this.maxChunkPageNumber : page;
      this.minChunkPageNumber =
        this.minChunkPageNumber && this.minChunkPageNumber < page ? this.minChunkPageNumber : page;
    },
    showDeleteAssetModal() {
      this.$modal.show(this.deleteModalName);
    },
    deleteAsset() {
      this.$store.dispatch('deleteAssetAction', this.assetId).then(async () => {
        const assetId = this.assetId;
        this.$modal.hide(this.deleteModalName);
        let redirectId = this.hasNext
          ? await this.getNextAssetId()
          : this.hasPrevious
          ? await this.getPreviousAssetId()
          : null;

        this.$store.commit(
          'setAssetsDetailsIdsListState',
          this.assetsDetailsIdsListState.filter(id => id !== assetId)
        );
        this.$store.commit('removeFromFamilyTreeLibraryAssetsState', assetId);
        this.$store.commit('setAssetWasDeletedState', true);

        if (redirectId) {
          this.$router.push({name: 'familytree-library-asset-details', params: {assetId: redirectId}});
        } else {
          this.goToLibrary();
        }
      });
    },
    toggleDetails() {
      this.areMobileDetailsShown = !this.areMobileDetailsShown;
    },
    onHideImage() {
      this.areDetailsHidden = true;
      this.rotationAngle = 0;
    },
    rotateImage() {
      this.rotationAngle = (this.rotationAngle + 90) % 360;
    },
    onRotationSaved() {
      this.rotationAngle = 0;
    },
    onImageLoad() {
      this.$store.dispatch('preloadNextFamilyTreeLibrarySrcsAction', {displayAssetId: this.assetId});
    },
    toggleOcrSelection() {
      this.isOcrSelectionMode = !this.isOcrSelectionMode;
      if (!this.isOcrSelectionMode) {
        this.isOcrAreaSelected = false;
        this.isOcrSelectedOnce = false;
      }
    },
    showConfirmationOcr(value) {
      this.isOcrAreaSelected = !!value;
      if (this.isOcrAreaSelected) {
        this.isOcrSelectedOnce = true;
        this.confirmActionClasses = ['highlighted'];
        setTimeout(() => {
          this.confirmActionClasses = [];
        }, 1500);
      }
    },
    confirmOcrSelection() {
      this.$refs['images-gallery-overlay'].confirmOcrSelection();
    }
  },
  components: {
    SaveRotationButton,
    AssetDownloadButton,
    AssetUploadMeta,
    AssetEditFormContainer,
    ImagesGalleryOverlay,
    AssetDetailsToggle,
    DeleteConfirmModalContent,
    DeleteIcon,
    PencilIcon,
    RotateIcon,
    OcrIcon,
  },
  name: 'AssetDetailsContainer',
};
</script>

<style lang="scss" scoped>
@import '@common/style/gallery.info';
.images-gallery-overlay {
  z-index: calc(#{$main-menu-z-index} + 1);
}
.ocr-select-confirm {
  transition: all .8s ease;
}
.ocr-select-waiting {
  word-break: break-word;
}
.ocr-select-confirm.highlighted {
  // show white opaque shadow background and shadow
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.2);
}
</style>
