<template>
  <div class="bulk-upload-modal-content">
    <div class="header">
      <h4>Bulk Tag</h4>
      <span>Apply to {{ filesLen }} files...</span>
    </div>
    <people-select-container
      :value="persons"
      :family-tree-id="familyTreeId"
      @select="peopleSelect"
      @remove="peopleRemove"
      @clear="clearPeople"
    ></people-select-container>

    <family-tree-location-multiselect-container
      placeholder="Place"
      :location="location"
      :family-tree-id="familyTreeId"
      @clear="clearPlace"
      @select="placeSelect"
    ></family-tree-location-multiselect-container>

    <date-multiselect :value="event_date" @select="dateSelect" @clear="clearDate"></date-multiselect>

    <textarea class="full-width" @input="changeDescription" placeholder="Description" @keyup.prevent />

    <mcr-button @click="confirmHandler">Upload</mcr-button>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';

import FamilyTreeLocationMultiselectContainer from '@/components/common/FamilyTreeLocationMultiselectContainer';
import PeopleSelectContainer from '@/components/common/inputs/FamilyTreePeopleMultiselectContainer';

import DateMultiselect from '@/components/modules/familyTreeLibrary/common/DateMultiselect';

export default {
  props: {
    filesLen: Number,
    familyTreeId: [String, Number],
  },
  data() {
    return {
      persons: [],
      location: null,
      event_date: null,
      description: null,
    };
  },
  methods: {
    peopleSelect(person) {
      this.persons.push(person);
    },
    peopleRemove(person) {
      this.persons = this.persons.filter(item => person.object_id !== item.object_id);
    },
    clearPeople() {
      this.persons = [];
    },
    clearPlace() {
      this.place = null;
    },
    placeSelect(place) {
      this.location = place;
    },
    dateSelect(date) {
      this.event_date = date;
    },
    clearDate() {
      this.event_date = null;
    },
    changeDescription(event) {
      this.description = event.target.value;
    },
    confirmHandler() {
      const eventDate = this.event_date && this.event_date.value;
      this.$emit('confirm', {
        persons: this.persons,
        location: this.location,
        event_date: eventDate,
        description: this.description,
      });
    },
  },
  components: {McrButton, FamilyTreeLocationMultiselectContainer, PeopleSelectContainer, DateMultiselect},
};
</script>

<style lang="scss" scoped>
.bulk-upload-modal-content {
  padding: 20px;

  > * {
    margin-bottom: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .full-width {
    width: 100%;
  }

  @media only screen and (min-width: $breakpoint-phablet) {
    width: 500px;
  }
}
</style>
